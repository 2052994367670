import React, { Suspense, lazy } from "react";
import Loader from "./components/lib/Loader";
import { preload } from "swr";
import { API_GET_DATA } from "./services/ApiEndPoint";
import { fetcher } from "./services/ApiServices";

const Routes = lazy(() => {
  return new Promise((resolve) => {
    preload(API_GET_DATA, fetcher);
    setTimeout(() => resolve(import("./Routes")), 700);
  });
});

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes />
    </Suspense>
  );
};

export default App;
