import axios from "axios";
import { API_URL } from "./ApiEndPoint";
import { toast } from "react-toastify";
import { store } from "../redux/Store";
import { logout } from "../redux/authSlice";

const Api = axios.create({
  baseURL: API_URL
});

Api.interceptors.request.use((req) => {
  const token = store.getState().authSlice.token;
  req.headers.Authorization = `Bearer ${token}`;
  return req;
});

Api.interceptors.response.use(
  async (response) => {
    if (response.data.status === "RC200") {
      response.data.message && toast.success(response.data.message);
      return response?.data;
    }
    if (response.data.status === "RC100") {
      if (response.data.message != "Please send required parameters") {
        toast.error(response.data.message);
      }
    }
    if (response.data.status === "RC300") {
      store.dispatch(logout());
      window.location.href = "/login";
      toast.error(response.data.message);
    } else {
      return response.data;
    }
  },
  (err) => {
    if (err.message === "Network Error") {
      toast.error("You are not connected to Internet");
    }

    throw new Error(err);
  }
);

export default Api;

const fetcher = (url) => Api(url).then((res) => res);

export { fetcher };
